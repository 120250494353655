import React, { useState } from 'react';
import { Container, Col, Row, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./Contact.css";
import contactimages from "./Images/Map.png";
import groupimages from "./Images/Group.png";
import overlapImage from "./Images/conversation-businessman-and-customer.png";
import touchimages from "./Images/business-deal.png";
import touchimages9 from "./Images/Group 283.png";
import icons8 from "./Images/icon.png";
import icons9 from "./Images/icon (1).png";

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const queryParams = new URLSearchParams(formData).toString();
            await fetch('https://itilmaamapi.adef.tech/Create_ContactForm?'+ queryParams, {
                method: 'POST'
            });

            alert('Form submitted successfully!');
            setFormData({
                name: '',
                email: '',
                message: ''
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form. Please try again later.');
        }
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Container fluid className="p-0">
            <div className="FAQContainer">
                <div className="FAQItem">
                    <h3>Get In Touch</h3>
                </div>
            </div>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="contact-images">
                            <img src={contactimages} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={6} className='group-images-container'>
                                <div className="group-images">
                                    <img src={groupimages} alt="" />
                                </div>
                                <div className="overlap-image">
                                    <img src={overlapImage} alt="" />
                                </div>
                            </Col>
                            <Col md={6} className='ml-5'>
                                <div className='contact-form'>
                                    <p className='contact'>contact</p>
                                    <h4>We’re happy to discuss
                                        your project & answer</h4>
                                    <hr className='hrtag' />
                                </div>
                                <div className='info mb-4'>
                                    <h6>278X+G93, Jaale Siyaad Rd, Mogadishu, Somalia</h6>
                                    <img src={icons8} alt="" className="phone-icon" />
                                </div>
                                <hr className='hrtag' />
                                <div className='info mb-4'>
                                    <h6>itilmaamgroup@gmail.com <br />+252619100021</h6>

                                    <img src={icons9} alt="" className="phone-icon" />
                                </div>
                                <hr className='hrtag' />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='mt-5'>
                <Row>
                    <Col md={12} className='from-pages mt-5'>
                        <Row className='mt-5'>
                            <Col md={6} className='touch-from'>
                                <Form className='mt-5' onSubmit={handleSubmit}>
                                    <Row>
                                        <p>ASK ANYTHING</p>
                                        <h5 className='mb-5'>Get in touch</h5>
                                        <Col md={4} className='ml-5'>
                                            <Form.Group controlId="name">
                                                <Form.Label>FULL NAME</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Your name"
                                                    name="name"
                                                    value={formData.fullName}
                                                    onChange={handleInputChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={4} className='ml-5'>
                                            <Form.Group controlId="email">
                                                <Form.Label>EMAIL ADDRESS</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Your email"
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className='ml-5'>
                                            <Form.Group controlId="message">
                                                <Form.Label>MESSAGE</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows={3}
                                                    placeholder="Your message"
                                                    name="message"
                                                    value={formData.message}
                                                    onChange={handleInputChange}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Button className='button-contact' type="submit">
                                        Submit <FontAwesomeIcon icon={faArrowRight} />
                                    </Button>
                                </Form>
                            </Col>
                            <Col md={6} className='p-0'>
                                <div className="touch-images">
                                    <img src={touchimages} alt="" />
                                </div>
                                <div className="touch-images9">
                                    <img src={touchimages9} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

        </Container>
    );
}

export default Contact;
