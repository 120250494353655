import React from 'react';
import './Css/Checkout.css';
import productimages from "./Images/bg.png"

const Checkout = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-9">
                    <div className="row product-card">
                        <div className="col-3">
                            <div className="product-images">
                                <img src={productimages} alt="" />
                            </div>
                        </div>
                        <div className="col-3 mt-5">
                            <h5 className="card-title">Product Name</h5>
                            <h6 className="card-subtitle mb-2 text-muted">Product Category</h6>
                        </div>
                        <div className="col-3 mt-5">
                            <p className="card-text">Quantity: 1</p>
                        </div>
                        <div className="col-3">
                            <p className="card-text">Price: $50</p>
                            <p className="card-text">Discount Price: $40</p>
                            <div className="row">
                                <div className="col-6">
                                    <button className="btn btn-danger btn-block">Delete</button>
                                </div>
                                <div className="col-6">
                                    <button className="btn btn-info btn-block">Add to Wishlist</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 text-center">
                    <div className="summary-card product-card mb-5">
                        <div className="card-body">
                            <h5 className="card-title">Order Summary</h5>
                            <p className="card-text">
                                {/* Add your order summary details here */}
                                <div>Subtotal: $50</div>
                                <div>Shipping: $5</div>
                            </p>
                            <button className="shippingbutton mt-5">PROCEED TO SHIPPING</button>
                        </div>
                    </div>
                    <div className="coupon-code mb-5"> {/* Corrected */}
                        <h5>Coupon Code</h5>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="" aria-describedby="basic-addon2" />
                            <div class="input-group-append">
                                <button class="input-group-text" id="basic-addon2">APPLY</button>
                            </div>
                        </div>
                        <p>View all coupons</p>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Checkout;
