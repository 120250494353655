import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import "./Profile.css";
import ProfileSideNav from "./ProfileSideNav";
import { BsPencil } from "react-icons/bs";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"
function Profile() {
  const [userData, setUserData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [authorId, setAuthorId] = useState(localStorage.getItem('author_id') || "");
  useEffect(() => {
    fetchUserProfile();
  }, [authorId]);

  const handleEditModeToggle = () => {
    setIsEditMode((prevMode) => !prevMode);
  };
  const fetchUserProfile = async () => {
    if (!authorId) return;

    try {
      const response = await fetch(
        `https://itilmaamapi.adef.tech/Get_User?authorid=${authorId}&usertype=4`
      );
      if (!response.ok) {
        throw new Error('Failed to fetch user profile');
      }
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };
  const handleInputChange = (field, value) => {
    setUserData((prevUserData) => ({
      ...prevUserData,
      [field]: value,
    }));
  };
debugger;
  const handleSave = async (e) => {
    e.preventDefault();
    debugger;
    const formData = new URLSearchParams(userData);
    const url = "https://itilmaamapi.adef.tech/Edit_User";
 
  
    debugger;
    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
      },
      });

      const responseData = await response.json();

      if (responseData.responseCode == 0) {

        toast.success(responseData.responsemessage, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        // Redirect to home page
        window.location.href = "/home";
      } else {
        // Login failed
        toast.error(
          responseData.responsemessage || "Invalid credentials.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } catch (error) {
      // Error during login
      toast.error("An error occurred during login.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      console.error("Fetch error:", error);
    }
  };
  return (
    <>

      <Container>
        <Row className="mt-5 mb-5">
          <Col md={4}>
            <ProfileSideNav />
          </Col>
          <Col md={8} className="mt-5">
            {userData && (
              <Form className="rightsidedata">
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={userData.Name || ""}
                        className="custom-form-control"
                        readOnly={!isEditMode}
                        onChange={(e) => handleInputChange("Name", e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={userData.Email || ""}
                        className="custom-form-control"
                        readOnly
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formNumber">
                      <Form.Label>Number</Form.Label>
                      <Form.Control
                        type="number"
                        value={userData.Phone || ""}
                        className="custom-form-control"
                        readOnly={!isEditMode}
                        onChange={(e) => handleInputChange("Phone", e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formPassword">
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        type="password"
                        value={userData.Password || ""}
                        className="custom-form-control"
                        readOnly={!isEditMode}
                        onChange={(e) => handleInputChange("Password", e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="d-flex justify-content-between">
                    <Button variant="primary" onClick={handleSave} disabled={!isEditMode}>
                      Save
                    </Button>
                    <BsPencil size={24} className="edit-icon" onClick={handleEditModeToggle} />
                  </Col>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
        <ToastContainer />
      </Container>
    </>
  );
}

export default Profile;
