import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProfileSideNav from "./ProfileSideNav";
import "./Profile.css";
import { FaMobileAlt, FaWhatsapp  } from "react-icons/fa";
function Customersupport() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={4}>
                        <ProfileSideNav />
                    </Col>
                    <Col md={8} className='rightsidedata mt-5'>
                        <h5>CUSTOMER SUPPORT</h5>
                        <p><FaMobileAlt /> +252619100021</p>
                        <p><FaWhatsapp /> +252619100021</p>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default Customersupport;