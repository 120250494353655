import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsCart } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa6';
import { Container, Row, Col } from 'react-bootstrap';
import SearchBar from './SearchBar'; // Import the SearchBar component
import './Header.css';
import axios from "axios";

import { FaRegHeart } from "react-icons/fa";
import { useGlobal } from './GlobalContext';

const Header = () => {
    const [username, setUsername] = useState(localStorage.getItem('username') || "");
    const [cartProductsCount, setCartProductsCount] = useState(0); // Initialize cart products count to 0
    const [wishlistCount, setWishlistCount] = useState(0);
    const [logoUrl, setLogoUrl] = useState('');



    const {globalWishlist, updateGlobalWishlist} = useGlobal();

    localStorage.setItem("cartcount", cartProductsCount.cartcount);
    localStorage.setItem("wishlistcount", wishlistCount);


    useEffect(() => {
        debugger;
        const fetchLogo = async () => {
            try {
                const response = await axios.get('https://itilmaamapi.adef.tech/GetAdminAdress');
                if (response.status === 200 && response.data && response.data.logo) { // Check for 'logo'
                    
                    setLogoUrl(response.data.logo); // Use 'logo' from the API response
                }
            } catch (error) {
                console.error("Error fetching logo:", error);
            }
        };
        
        const fetchCartProductsCount = async () => {
            const cartId = localStorage.getItem('cart_id');
            try {
                const cartResponse = await axios.get(
                    `https://itilmaamapi.adef.tech/Cartcount?cart_id=${cartId}`
                );
                if (cartResponse.status === 200) {
                    setCartProductsCount(cartResponse.data);
                }
            } catch (error) {
                console.error("Error fetching cart details:", error);
            }
        };
        debugger;
        const fetchWishlistCount = async () => {
            const authorId = localStorage.getItem('author_id');
            try {
                const wishlistResponse = await axios.get(
                    `https://itilmaamapi.adef.tech/Wishlistcount?authorid=${authorId}`
                );
                
                if (wishlistResponse.status === 200) {
                    updateGlobalWishlist(wishlistResponse.data.wishlistcount);
                    setWishlistCount(wishlistResponse.data.wishlistcount);
                }
            } catch (error) {
                console.error("Error fetching wishlist details:", error);
            }
        };

        fetchCartProductsCount();
        fetchWishlistCount();
        fetchLogo();
    }, []);




    debugger;
    return (
        <Container>
            <Row className="header align-items-center">
            <Col xs={6} md={3} className="header__logo">
                    <Link to="/">
                        {logoUrl ? (
                            <img src={logoUrl} className="logo" alt="Logo" />
                        ) : (
                            "ITILMAAM" // Fallback text in case the logo fails to load
                        )}
                    </Link>
                </Col>
                <Col xs={12} md={6} className="header__search d-none d-md-flex">
                    <SearchBar />
                </Col>
                <Col xs={6} md={3} className="header__icons d-flex justify-content-end">
                    {/* Wishlist Icon */}
                    <div className="header__icon">
                        <Link className='link' to="/wishlist">
                            <FaRegHeart className="wishlist-icon" />
                            <span className="cart-count">{globalWishlist}</span>
                        </Link>
                    </div>
                    <div className="header__icon">
                        <Link className='link' to="/cart">
                            <BsCart className="cart-icon" />
                            <span className="cart-count">{cartProductsCount.cartcount}</span>
                        </Link>
                    </div>
                    {username ? (
                        <div className="header__icon">
                            <Link to="/profile" className="link">
                                <FaRegUser />
                                <span className="username">{username}</span>
                            </Link>
                        </div>
                    ) : (
                        <div className="header__icon">
                            <Link className='link' to="/login">
                                <FaRegUser />
                            </Link>
                        </div>
                    )}
                </Col>
                <Col xs={12} className="header__search d-flex d-md-none mt-2">
                    <SearchBar />
                </Col>
            </Row>
        </Container>
    );
};

export default Header;
