import { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {

    const [globalWishlist, setGlobalWishlist] = useState(0);

    const updateGlobalWishlist = (newvalue) => {
        setGlobalWishlist(typeof(newvalue) == 'number' ? newvalue : 0)
    }

    return (
    < GlobalContext.Provider value = {{ globalWishlist, updateGlobalWishlist }} >
        { children }
    </GlobalContext.Provider >
    );
};

export const useGlobal = () => {
    return useContext(GlobalContext);
}