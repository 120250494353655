import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
function Breadcrumbs({ items }) {

    return (
        <>
         <Breadcrumb>
      {items.map((item, index) => (
        <Breadcrumb.Item key={index} active={index === items.length - 1}>
          {index !== items.length - 1 ? (
            <Link to={item.link}>{item.text}</Link>
          ) : (
            item.text
          )}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
        </>
    )

}
export default Breadcrumbs;