import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { BsPencil } from "react-icons/bs";
import Address from "./Address";
import "./Profile.css";
import { RiProfileFill, RiCustomerService2Line } from "react-icons/ri";
import { CiDeliveryTruck, CiLogout } from "react-icons/ci";
import { TbTruckDelivery } from "react-icons/tb";
import { PiPasswordFill } from "react-icons/pi";
debugger;
function ProfileSideNav() {
    const [userData, setUserData] = useState(null);
    const [email, setEmail] = useState(null);

    useEffect(() => {
        const username = localStorage.getItem("username");
        const email =localStorage.getItem("email");
        setUserData(username);
        setEmail(email);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("username");
        localStorage.removeItem("author_id");
        window.location.href = "/";
    };

    return (
        <>
            <Container>
                <Row className="mt-5 mb-5">
                    <Col md={12}>
                        <div className="profilesidenav">
                            <div className="">
                                <div className="d-flex profileimage">
                                    <img
                                        className="profile-icons"
                                        variant="top"
                                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSd67Lw9n7YRrWDBTswbzagzohIZxPdOjHoAQ&s"
                                    />
                                    <div className="user-info ml-3">
                                        <h5>{userData}</h5>
                                        <p>{email}</p>
                                    </div>
                                </div>
                            </div>
                            <Card>
                                <Card.Body>
                                    <div className="menu-buttons">
                                        <Link to="/profile"
                                            className="btnsidenav mb-3 mt-3"
                                            variant=" text-start"
                                        >
                                            <RiProfileFill /> My Profile
                                        </Link>
                                        <Link to="/address"
                                            className="btnsidenav mb-3"
                                            variant=" text-start"
                                        >
                                            <CiDeliveryTruck /> Delivery Address
                                        </Link>
                                        <Link to="/myorders"
                                            className="btnsidenav mb-3"
                                            variant=" text-start"
                                        >
                                            <TbTruckDelivery /> Orders
                                        </Link>
                                        <Link to="/customercare"
                                            className="btnsidenav mb-3"
                                            variant=" text-start"
                                        >
                                            <RiCustomerService2Line /> Customer Care
                                        </Link>
                                        <Link to="/changepassword"
                                            className="btnsidenav mb-3"
                                            variant=" text-start"
                                        >
                                            <PiPasswordFill /> Change Password
                                        </Link>
                                        <Link
                                            className="btnsidenav mb-3"
                                            variant="light text-start"
                                            onClick={handleLogout}
                                        >
                                            <CiLogout /> Logout
                                        </Link>
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ProfileSideNav;
