import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import contactimages9 from "./Images/About.png";
import Icons95 from "./Images/Vector.png";
import Icons96 from "./Images/icon.png";
import Icons97 from "./Images/icon (1).png";
import Icons98 from "./Images/icon (2).png";
import Icons99 from "./Images/Journey.png";
import "../components/Css/Aboutus.css";

const Aboutus = () => {
    return (
        <Container fluid className="p-0">
            <div className="FAQContainer">
                <div className="FAQItem">
                    <h3>Since from 2000</h3>
                </div>
            </div>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="contact-images">
                            <img src={contactimages9} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col md={12} className='mt-5'>
                        <Row className="d-flex justify-content-center">
                            <Col md={3} className="border-right">
                                <div className="contact-images9 ml-5`">
                                    <img src={Icons95} alt="" />
                                </div>
                                <div>
                                    <h6>Quality Team</h6>
                                    <p className='offers'>Classified listing marketplace <br />
                                        offers perfect.</p>
                                </div>
                            </Col>
                            <Col md={3} className="border-right">
                                <div className="contact-images9">
                                    <img src={Icons96} alt="" />

                                </div>
                                <div>
                                    <h6 className='offers'>Top-Notch Item</h6>
                                    <p>Classified listing marketplace <br />
                                        offers perfect.</p>
                                </div>
                            </Col>
                            <Col md={3} className="border-right">
                                <div className="contact-images9">
                                    <img src={Icons97} alt="" />

                                </div>
                                <div>
                                    <h6>Good Support</h6>
                                    <p className='offers'>Classified listing marketplace <br />
                                        offers perfect.</p>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="contact-images9">
                                    <img src={Icons98} alt="" />
                                </div>
                                <div>
                                    <h6> Easy To Use</h6>
                                    <p className='offers'> Classified listing marketplace <br />
                                        offers perfect.</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container fluid className='mt-5'>
                <Row>
                    <Col md={12}>
                        <div className="contact-images">
                            <img src={Icons99} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Aboutus;
