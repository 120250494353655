import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import { Container, Row, Col } from 'react-bootstrap';

const Navbar = () => {
    const [areas, setAreas] = useState([]);
    const [categories, setCategories] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        fetchAreas();
        fetchCategories();
    }, []);

    const fetchAreas = async () => {
        try {
            const response = await fetch('https://itilmaamapi.adef.tech/List_Area');
            if (response.ok) {
                const data = await response.json();
                setAreas(data);
            } else {
                throw new Error('Failed to fetch areas data');
            }
        } catch (error) {
            console.error('Error fetching areas:', error);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await fetch('https://itilmaamapi.adef.tech/List_Category');
            if (response.ok) {
                const data = await response.json();
                setCategories(data);
            } else {
                throw new Error('Failed to fetch categories data');
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    const handleCategoryChange = (e) => {
        const selectedCategoryId = e.target.value;
        navigate(`/productslist?cat=${selectedCategoryId}`);
    };

    return (
        <Container className='navbar-container'>
            <Row className="navbar align-items-center">
                <Col xs={12} md={8} className="navbar__left">
                    <div className="navbar__dropdown">
                        <select onChange={handleCategoryChange}>
                            <option value="">Categories</option>
                            {categories.map(category => (
                                <option key={category.id} value={category.name}>{category.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="navbar__dropdown">
                        <select>
                            <option value="">Areas</option>
                            {areas.map(area => (
                                <option key={area.id} value={area.area_name}>{area.area_name}</option>
                            ))}
                        </select>
                    </div>
                    <Link to="/home" className="navbar__button">Home</Link>
                    <Link to="/productslist" className="navbar__button">Products</Link>
                    <Link to="/faqspages" className="navbar__button">FAQ'S</Link>
                    <Link to="/contact" className="navbar__button">Contact</Link>
                </Col>
            </Row>
        </Container>
    );
};

export default Navbar;
