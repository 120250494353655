import React from "react";
import "./Products.css"; // Import your CSS file for styling
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import images from "./Images/Images.png";
import { Container, Row, Col } from "react-bootstrap";
import { GoStarFill } from "react-icons/go";
import { Link } from "react-router-dom";

const Products = () => {
  return (
    <Container className="product-container">
      <Row>
            {/* Texts and Button */}
            <Col md={4} className="text-start">
              <div className="text-container mt-5">
                <p className="stars">
                  <GoStarFill /><GoStarFill /><GoStarFill /><GoStarFill /><GoStarFill /><br />
                  Trusted By 1M+ users
                 </p>
                <h1 className="heading9">
                  The largest <br />
                  site for buying <br />
                  in Somalia.
                </h1>
                <Link to={'/productslist'}>
                <button className="explore-button">
                  Explore Products <FontAwesomeIcon icon={faArrowRight} />
                </button>
                </Link>
              </div>
            </Col>

            {/* Images */}
            <Col md={8}>
              <div className="image-container">
                <img src={images} alt="" />
              </div>
            </Col>
      </Row>
    </Container>
  );
};

export default Products;
