import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import "./Css/OrderDetails.css";

function OrderDetails({ cartamount, coupon }) {
  console.log(cartamount);
  console.log(coupon);
  const [finalAmount, setFinalAmount] = useState(0);
  const discountAmount = localStorage.getItem("couponamount");
  useEffect(() => {

    debugger;
    if (cartamount && coupon != null) {
      debugger;
      //const discountAmount = coupon.discountamount || 0;
      const discountAmount = localStorage.getItem("couponamount");
      if (discountAmount == undefined) {
        const discount = discountAmount == undefined && discountAmount == null ? discountAmount : 0;
        const totalPrice = cartamount.price || 0;

        const calculatedAmount = totalPrice - discount;
        setFinalAmount(calculatedAmount);
      }
      else{
        const totalPrice = cartamount.price || 0;

        const calculatedAmount = totalPrice - discountAmount;
        setFinalAmount(calculatedAmount);
      }


    }
    else if (coupon == null) {
      const totalPrice = cartamount.price || 0;

      const calculatedAmount = totalPrice;
      setFinalAmount(calculatedAmount);
    }
  }, [cartamount, coupon]);

  return (
    <div className="order-details-container mt-4">
      {cartamount && (
        <div className="order-details-content p-3">
          <h5 className="order-details-title">Order Details</h5>
          <div className="order-details-item mt-4 d-flex justify-content-between">
            <h6 className="order-details-label">Bag total  </h6>
            <h6 className="order-details-value">$ {cartamount.amount}</h6>
          </div>
          <div className="order-details-item mt-3 bag-discount d-flex justify-content-between">
            <h6 className="order-details-label">Bag discount  </h6>
            <h6 className="order-details-value">-$ {cartamount.discount_amount}</h6>
          </div>
          {discountAmount ? (
            <div className="order-details-item mt-3 bag-discount d-flex justify-content-between">
              <h6 className="order-details-label">Coupon </h6>
              <h6 className="order-details-value">-$ {discountAmount}</h6>
            </div>
          ) : null}
          <div className="order-details-item mt-3 order-total d-flex justify-content-between">
            <h6 className="order-details-label">Order Total</h6>
            <h6 className="order-details-value">$ {finalAmount}</h6>
          </div>
          <p className='cod mt-3'>Cash on Delivery Available</p>
        </div>
      )}
    </div>
  );
}

export default OrderDetails;
