import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import "./Css/SearchBar.css";

const SearchBar = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [suggestions, setSuggestions] = useState([]);

    const handleSearchChange = async (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        try {
            const response = await fetch(`https://itilmaamapi.adef.tech/ProductSearch?searchname=${query}`);
            const data = await response.json();
            setSuggestions(data); // Update suggestions state
        } catch (error) {
            console.error('Error fetching search suggestions:', error);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        setSearchQuery(""); // Clear search query
        setSuggestions([]); // Clear suggestions
        // Do something with the suggestion, like navigating to the product details page
    };

    return (
        <div className="header__search">
            <input 
                type="text" 
                placeholder="Search" 
                className="header__searchInput" 
                value={searchQuery}
                onChange={handleSearchChange}
            />
            <FontAwesomeIcon icon={faSearch} className="header__searchIcon" />
            {/* Display suggestions */}
            <div className="searchSuggestions">
                {suggestions.map((product, index) => (
                    <Link 
                        to={`/product-details?productId=${product.id}`} 
                        key={index} 
                        className="suggestionItem"
                        onClick={() => handleSuggestionClick(product)}
                    >
                        <span>{product.name}</span>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default SearchBar;
