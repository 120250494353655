import React, { useState, useEffect } from 'react';
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import ProfileSideNav from "./ProfileSideNav";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function EdtiAddress() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    const authorId = localStorage.getItem('author_id') || "";
    const [addressData, setAddressData] = useState({
        id: "",
        name: "",
        address: "",
        city: "",
        phone: "",
        state: "",
        pincode: "",
        area: "",
        email: "",
        usertype: 4,
        authorId: authorId,
    });

    useEffect(() => {
        if (id) {
            getAddressById();
        }
    }, [id]);

    const getAddressById = async () => {
        debugger;
        try {
            const url = `https://itilmaamapi.adef.tech/Get_Address?authorid=${authorId}&id=${id}&usertype=${4}`;
            const response = await axios.get(url);
            const responseData = response.data;
            if (responseData) {
                setAddressData(responseData);
            } else {
                toast.error(responseData.responsemessage || "Error fetching saved addresses");
            }
        } catch (error) {
            toast.error("An error occurred:", error.message);
            console.error("Error:", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddressData(prevData => ({
            ...prevData,
            [name]: value,
            authorId: authorId
        }));
    };

    const editsubmit = async (e) => {
        e.preventDefault();
        debugger;
        try {
            // const requiredFields = ['name', 'phone', 'city', 'state', 'pincode', 'area', 'email', 'id'];
            const url = "https://itilmaamapi.adef.tech/Edit_Address";
            const formData = new URLSearchParams(addressData);
            formData.append('authorId', authorId);
            formData.append('usertype', 4);
            debugger;
            const response = await fetch(url, {
                method: "POST",
                body: formData,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
            const responseData = await response.json();

            if (responseData.responseCode === 0) {
                toast.success(responseData.responsemessage, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setTimeout(() => {
                    window.location.href = "/address";
                }, 4000);
            }
            else {
                toast.error(
                    responseData.responsemessage,
                    {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
        }
        catch (error) {
            toast.error("An error occurred:", error.message);
            console.error("Error:", error);
        }
    }



    return (
        <>
            <Container className="mt-5">
                <Row>
                    <Col md={4}>
                        <ProfileSideNav />
                    </Col>
                    <Col md={8} className="mt-5 rightsidedata">
                        <h5>EDIT ADDRESS</h5>
                        <Form className="form-container mt-3" onSubmit={editsubmit}>
                            <Row>
                                <Form.Group controlId="formId">
                                    <Form.Control
                                        type="hidden"
                                        name="id"
                                        value={addressData.id}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                                <Col md={6}>
                                    <Form.Group controlId="formName">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Name"
                                            name="name"
                                            value={addressData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formEmail">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="Enter Email"
                                            name="email"
                                            value={addressData.email}
                                            onChange={handleChange}

                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formPhone">
                                        <Form.Label>Phone</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Phone"
                                            name="phone"
                                            value={addressData.phone}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formState">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter State"
                                            name="state"
                                            value={addressData.state}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formCity">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter City"
                                            name="city"
                                            value={addressData.city}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formPincode">
                                        <Form.Label>Pincode</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Pincode"
                                            name="pincode"
                                            value={addressData.pincode}
                                            onChange={handleChange}

                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formArea">
                                        <Form.Label>Area</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Area"
                                            name="area"
                                            value={addressData.area}
                                            onChange={handleChange}

                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={12}>
                                    <Form.Group controlId="formAddress">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            type="text"
                                            placeholder="Enter Address"
                                            name="address"
                                            value={addressData.address}
                                            onChange={handleChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Button variant="success" className="btn btn-form-control" type="submit">Save</Button>
                        </Form>
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    )
};
export default EdtiAddress;