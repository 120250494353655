import { React, useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ProfileSideNav from "./ProfileSideNav";
import axios from "axios";
import './Css/Myorders.css';
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function MyOrderDetails() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const cartid = searchParams.get("cartid");
    const [orders, setOrders] = useState([]);

    debugger;
    console.log(orders);
    debugger;
    useEffect(() => {
        fetchOrders();
    }, [cartid]);
    const fetchOrders = async () => {
        debugger;
        try {
            debugger
            const authorId = localStorage.getItem("author_id");
            const userType = 4;
            const url = `https://itilmaamapi.adef.tech/Myorderdetails?authorid=${authorId}&usertype=${userType}&cart_id=${cartid}`;
            debugger;
            const response = await axios.get(url);
            const responseData = response.data;
            debugger;
            if (responseData != null) {
                setOrders(responseData);
            } else {
                toast.error(responseData.responsemessage || "Error fetching orders");
            }
        } catch (error) {
            toast.error("An error occurred:", error.message);
            console.error("Error:", error);
        }
    };


    return (
        <>
            <Container>
                <Row>
                    <Col md={4}>
                        <ProfileSideNav />
                    </Col>
                    <Col md={8} className="rightsidedata mt-5">
                        <Row className="orderdetailsdiv">
                            <Col md={1}>
                                Image</Col>
                            <Col md={3}>
                                <p>Product Name</p>
                            </Col>
                            <Col md={2}>Per Price</Col>
                            <Col md={2}>Quantiy</Col>
                            <Col md={2}>Total Amount</Col>
                            <Col md={1}>Discount</Col>
                            <Col md={1} >Tax</Col>
                        </Row>
                        {orders && orders.length > 0 ? (
                            orders.map(order => (
                                <Row key={order.id} className="orderdetailsdiv" >
                                    <Col md={1}>
                                        <img src={order.image} className="w-100" alt="" /></Col>
                                    <Col md={3}>
                                        <p>{order.name}</p>
                                    </Col>
                                    <Col md={2}>{order.per_price}</Col>
                                    <Col md={2}>{order.quantity}</Col>
                                    <Col md={2}>{order.amount}</Col>
                                    <Col md={1}>{order.discount}</Col>
                                    <Col md={1}>{order.VATtax}</Col>
                                </Row>
                            ))
                        ) : (
                            <div>No orders found</div>
                        )}
                    </Col>
                </Row>
                <ToastContainer />
            </Container>
        </>
    )
}
export default MyOrderDetails;