import React, { useState,useEffect} from 'react';
import { Accordion, Card, Button, Col, Row, Container, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "./Faqspages.css";
import axios from "axios";

const Faqspages = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [faqs, setFaqs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Make an HTTP POST request to the specified URL
            await fetch('https://itilmaamapi.adef.tech/Create_ContactForm', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams(formData).toString()
            });
            alert('Form submitted successfully!');
            // Reset form data after successful submission
            setFormData({
                name: '',
                email: '',
                message: ''
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form. Please try again later.');
        }
    };
    useEffect(() => {
        const fetchFaqs = async () => {
          try {
            const response = await fetch("https://itilmaamapi.adef.tech/ListFAQs");
            const data = await response.json();
            setFaqs(data);
            setLoading(false);
          } catch (error) {
            console.error("Error fetching FAQs:", error);
            setError("Failed to load FAQs. Please try again later.");
            setLoading(false);
          }
        };
        fetchFaqs();
      }, []);
    
    // Function to handle form input changes
    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Container fluid className="p-0">
            <div className="FAQContainer">
                <div className="FAQItem">
                    <h3>FAQ Page</h3>
                </div>
            </div>
            <Container>
                <Row>
                    <Col md={12} >
                        <div>
                            <Row>
                                <Col md={3} className='mt-4'>
                                    <div className="topics-card">
                                        <Card>
                                            <Card.Body>
                                                <h5>Few Topics</h5>
                                                <Card.Title>Our Company</Card.Title>
                                                <Card.Title>Privacy Policy</Card.Title>
                                                <Card.Title>Refund Policy</Card.Title>
                                                <Card.Title>Price Plans</Card.Title>
                                            </Card.Body>
                                        </Card>
                                        <Card>
                                            <Card.Body>
                                                <h5>Ask Anything</h5>
                                                {/* Form for user input */}
                                                <Form onSubmit={handleSubmit}>
                                                    <Form.Group className="mb-3" controlId="formName">
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter your name"
                                                            name="name"
                                                            value={formData.name}
                                                            onChange={handleInputChange}
                                                            className="input-field"
                                                            required
                                                            style={{ border: "1px solid black" }} // Add inline style for border
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formEmail">
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Enter email"
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleInputChange}
                                                            className="input-field"
                                                            required
                                                            style={{ border: "1px solid black" }} // Add inline style for border
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formMessage">
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={3}
                                                            placeholder="Enter your message"
                                                            name="message"
                                                            value={formData.message}
                                                            onChange={handleInputChange}
                                                            className="input-field"
                                                            required
                                                            style={{ border: "1px solid black" }} // Add inline style for border
                                                        />
                                                    </Form.Group>
                                                    <Button type="submit" className="submit-button">
                                                        Submit <FontAwesomeIcon icon={faArrowRight} />
                                                    </Button>
                                                </Form>

                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                                <Col md={9} className='mt-4'>
                                    {loading ? (
                                        <p>Loading FAQs...</p>
                                    ) : error ? (
                                        <p>{error}</p>
                                    ) : (
                                        <Accordion defaultActiveKey={null}>
                                            {faqs.map((faq, index) => (
                                                <Accordion.Item eventKey={index.toString()} key={faq.id}>
                                                    <Accordion.Header className="faq-accordion-header">{faq.question}</Accordion.Header>
                                                    <Accordion.Body className="faq-accordion-body">
                                                        {faq.answer}
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Faqspages;
