import React from "react";
import "./Css/Testimonials.css"
import { Container, Row, Col } from "react-bootstrap";

const Testimonials = () => {
    <Container fluid className="testimonialdiv mt-5">
        <Row>
            <Col>
                <h1>hello</h1>
            </Col>
        </Row>
    </Container>
}
export default Testimonials;