import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useState } from "react";
import "./Profile.css";
import ProfileSideNav from "./ProfileSideNav";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PasswordStrengthBar from 'react-password-strength-bar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ChangePassword() {
    var authorId = localStorage.getItem("author_id");
    const [formData, setAddressData] = useState({
        Password: '',
        showPassword: false,
        usertype: 4,
        authorid: authorId,
    })
    const togglePasswordVisibility = () => { 
        setAddressData(prevData => ({
            ...prevData,
            showPassword: !prevData.showPassword
        }));
    };


    const { Password } = formData;
    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddressData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    const updatepassword = async (e) => {
        e.preventDefault();
        debugger;
        const url = "https://itilmaamapi.adef.tech/Password";
        const formDataToSend = new URLSearchParams(formData);
    
        try {
            const response = await fetch(url, {
                method: "POST",
                body: formDataToSend,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            });
    
            const responseData = await response.json();
    
            if (responseData.responseCode === 0) {
                toast.success(responseData.responsemessage, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
                setAddressData(prevData => ({
                    ...prevData,
                    password: ''
                }));
            } else {
                toast.error(
                    responseData.responsemessage || "Invalid credentials.",
                    {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    }
                );
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    



        return (
            <>
                <Container className="mt-5 mb-5">
                    <Row>
                        <Col md={4}>
                            <ProfileSideNav />
                        </Col>
                        <Col md={8} className='rightsidedata mt-5'>
                            <Form onSubmit={updatepassword}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group controlId="formName">
                                            <Form.Label>Change Password</Form.Label>
                                            <div className="password-input">
                                                <Form.Control
                                                    type={formData.showPassword ? "text" : "Password"}
                                                    placeholder="Enter Password"
                                                    name="Password"
                                                    value={formData.Password}
                                                    onChange={handleChange}
                                                    required
                                                />
                                                {formData.showPassword ? (
                                                    <FaEyeSlash onClick={togglePasswordVisibility} className="eye-icon" />
                                                ) : (
                                                    <FaEye onClick={togglePasswordVisibility} className="eye-icon" />
                                                )}
                                            </div>
                                        </Form.Group>

                                        <PasswordStrengthBar password={Password} />
                                        <Button variant="success" className="btn btn-form-control" type="submit">Update</Button>
                                    </Col>
                                </Row>

                            </Form>
                        </Col>
                    </Row>
                    <ToastContainer/>
                </Container>
            </>
        )
    }

    export default ChangePassword